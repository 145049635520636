var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.offers,"loading":_vm.loading,"loader-height":"2","sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortDesc,"options":_vm.options,"server-items-length":_vm.totalItems,"no-data-text":_vm._f("localize")('noDataAvailable'),"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.proposal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['proposal'])+" "),_c('i',{staticClass:"text-body-2"},[_vm._v(_vm._s(item['currency']))])]}},{key:"item.lead_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['lead_time'])+" "+_vm._s(_vm.leadTimeDays(item['lead_time']))+" ")]}},{key:"item.emissions",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item['emissions'] ? item['emissions'] : '--')+" ")]}},{key:"item.deadline_for_booking",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['deadline_for_booking']))}})]}},{key:"item.valid_to",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['valid_to']))}})]}},{key:"item.transport_mode_shortcode",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-7",attrs:{"color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.TRANSPORT_ICONS[item['transport_mode_shortcode']])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")(item['transport_mode_shortcode'])))])])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment)?_c('v-tooltip',{attrs:{"top":"","color":"black","max-width":"320"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-comment")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.comment))])]):_vm._e()]}},{key:"item.buttons",fn:function(ref){
var item = ref.item;
return [(_vm.isPriceRequestOnly())?[(_vm.isViewed(item))?_c('span',{staticClass:"success--text"},[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('viewed')))])]):_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"success","loading":_vm.viewedQueue.includes(item.id),"disabled":_vm.viewedQueue.includes(item.id) || _vm.isRejected(item) || _vm.isNotBooked(item) || _vm.isDeclined(item)},on:{"click":function($event){return _vm.viewedOffer(item)}}},[(_vm.viewedQueue.includes(item.id))?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):[_vm._v(" "+_vm._s(_vm._f("localize")('markAsViewed'))+" ")]],2)]:[(_vm.isBooked(item))?[_c('v-btn',{staticClass:"justify-center text-subtitle-2 mr-2",attrs:{"rounded":"","fab":"","x-small":"","depressed":"","color":"success","to":{name: 'booking-summary', query: {request_id: _vm.requestId, proposal_id: item.id}}}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"size":"20"}},'v-icon',attrs,false),on),[_vm._v("mdi-book-open-variant")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")('bookingSummaryOpen')))])])],1)]:[(_vm.isExpired(item))?[_vm._v(" "+_vm._s(_vm._f("localize")('expired_note'))+" "),_c('a',{attrs:{"href":"mailto:adhoc@fraktus.se"}},[_vm._v("adhoc@fraktus.se")])]:(_vm.isRejected(item) || _vm.isNotBooked(item) || _vm.isDeclined(item) || _vm.isActionRequired(item))?[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.STATUS_COLORS[item.status],"size":"20"}},[_vm._v(_vm._s(_vm.STATUS_ICONS[item.status]))]),_c('span',[_vm._v(_vm._s(_vm._f("localize")(item.status)))]),(_vm.isActionRequired(item))?_c('v-tooltip',{attrs:{"top":"","color":"black","content-class":"px-2","max-width":"300px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"20","color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v("mdi-information")])]}}],null,true)},[[_c('div',{staticClass:"py-1"},[_vm._v(_vm._s(_vm._f("localize")('refinementNote')))])]],2):_vm._e()],1)]:(_vm.isNew(item))?[_c('v-btn',{staticClass:"mr-2 btn-fixed-width",attrs:{"rounded":"","depressed":"","color":"success","to":{name: 'booking', query: {offer_id: item.id, request_id: _vm.requestId, mode: item['transport_mode_shortcode']}}}},[_vm._v(" "+_vm._s(_vm._f("localize")('book'))+" ")]),_c('v-btn',{staticClass:"btn-fixed-width",attrs:{"rounded":"","depressed":"","loading":_vm.rejectQueue.includes(item.id),"disabled":_vm.rejectQueue.includes(item.id)},on:{"click":function($event){return _vm.openReject(item)}}},[_vm._v(" "+_vm._s(_vm._f("localize")('reject'))+" ")])]:_vm._e()]]]}}])}),_c('v-pagination',{staticClass:"py-4",attrs:{"color":"primary","length":_vm.totalPages,"total-visible":7,"disabled":_vm.loading},on:{"input":function($event){_vm.offers = [];_vm.getOffers();}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('SimpleDialog',{ref:"rejectQuoteDialog",attrs:{"title":_vm._f("localize")('rejectQuote'),"text":_vm._f("localize")('rejectQuoteText'),"accept-text":_vm._f("localize")('rejectQuote'),"decline-text":_vm._f("localize")('cancel')},on:{"decline":_vm.cancelReject,"accept":_vm.rejectOffer}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }