<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="offers"
      :loading="loading"
      loader-height="2"
      :sort-by="defaultSortBy"
      :sort-desc="defaultSortDesc"
      :options.sync="options"
      :server-items-length="totalItems"
      :no-data-text="'noDataAvailable'|localize"
      hide-default-footer
    >
      <template v-slot:item.proposal="{ item }">
        {{ item['proposal'] }} <i class="text-body-2">{{ item['currency'] }}</i>
      </template>
      <template v-slot:item.lead_time="{ item }">
        {{ item['lead_time'] }} {{ leadTimeDays(item['lead_time']) }}
      </template>
      <template v-slot:item.emissions="{ item }">
        {{ item['emissions'] ? item['emissions'] : '--' }}
      </template>
      <template v-slot:item.deadline_for_booking="{ item }">
        <div v-html="$options.filters.formatDate(item['deadline_for_booking'])"/>
      </template>
      <template v-slot:item.valid_to="{ item }">
        <div v-html="$options.filters.formatDate(item['valid_to'])"/>
      </template>
      <template v-slot:item.transport_mode_shortcode="{ item }">
        <v-tooltip top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-7" v-bind="attrs" v-on="on" color="silver-chalice">
              {{ TRANSPORT_ICONS[item['transport_mode_shortcode']] }}
            </v-icon>
          </template>
          <span>{{ item['transport_mode_shortcode']|localize }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.comment="{ item }">
        <v-tooltip v-if="item.comment" top color="black" max-width="320">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" color="success" v-on="on" size="20">mdi-comment</v-icon>
          </template>
          <span>{{ item.comment }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.buttons="{ item }">
        <template v-if="isPriceRequestOnly()" class="d-flex justify-center">
          <span v-if="isViewed(item)" class="success--text">
            <strong>{{ 'viewed'|localize }}</strong>
          </span>
          <v-btn
            v-else
            rounded
            depressed
            color="success"
            :loading="viewedQueue.includes(item.id)"
            @click="viewedOffer(item)"
            :disabled="viewedQueue.includes(item.id) || isRejected(item) || isNotBooked(item) || isDeclined(item)"
          >
            <v-progress-circular
              v-if="viewedQueue.includes(item.id)"
              indeterminate
              :width="1"
              :size="20"
            />
            <template v-else>
              {{ 'markAsViewed'|localize }}
            </template>
          </v-btn>
        </template>
        <template v-else>
          <template v-if="isBooked(item)">
            <v-btn
              class="justify-center text-subtitle-2 mr-2"
              rounded
              fab
              x-small
              depressed
              color="success"
              :to="{name: 'booking-summary', query: {request_id: requestId, proposal_id: item.id}}"
            >
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" size="20">mdi-book-open-variant</v-icon>
                </template>
                <span>{{ 'bookingSummaryOpen'|localize }}</span>
              </v-tooltip>
            </v-btn>
          </template>
          <template v-else>
            <template v-if="isExpired(item)">
              {{ 'expired_note'|localize }} <a href="mailto:adhoc@fraktus.se">adhoc@fraktus.se</a>
            </template>
            <template v-else-if="isRejected(item) || isNotBooked(item) || isDeclined(item) || isActionRequired(item)">
              <div class="d-flex align-center justify-center">
                <v-icon :color="STATUS_COLORS[item.status]" size="20" class="mr-2">{{ STATUS_ICONS[item.status] }}</v-icon>
                <span>{{ item.status|localize }}</span>
                <v-tooltip v-if="isActionRequired(item)" top color="black" content-class="px-2" max-width="300px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" size="20" color='silver-chalice' class="ml-2">mdi-information</v-icon>
                  </template>
                  <template>
                    <div class="py-1">{{ 'refinementNote'|localize }}</div>
                  </template>
                </v-tooltip>
              </div>
            </template>
            <template v-else-if="isNew(item)">
              <v-btn
                class="mr-2 btn-fixed-width"
                rounded
                depressed
                color="success"
                :to="{name: 'booking', query: {offer_id: item.id, request_id: requestId, mode: item['transport_mode_shortcode']}}"
              >
                {{ 'book'|localize }}
              </v-btn>
              <v-btn
                class="btn-fixed-width"
                rounded
                depressed
                :loading="rejectQueue.includes(item.id)"
                :disabled="rejectQueue.includes(item.id)"
                @click="openReject(item)"
              >
                {{ 'reject'|localize }}
              </v-btn>
            </template>
          </template>
        </template>
      </template>
    </v-data-table>
    <v-pagination
      color="primary"
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      class="py-4"
      :disabled="loading"
      @input="offers = [];getOffers();"
    />
    <SimpleDialog
      ref="rejectQuoteDialog"
      :title="'rejectQuote'|localize"
      :text="'rejectQuoteText'|localize"
      :accept-text="'rejectQuote'|localize"
      :decline-text="'cancel'|localize"
      @decline="cancelReject"
      @accept="rejectOffer"
    />
  </div>
</template>
<script>
  import localize from '@/filters/localize';
  import SimpleDialog from '@/components/common/SimpleDialog';
  import api from '@/api/api';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import {STATUSES, STATUS_ICONS, STATUS_COLORS} from '@/enums/statuses';
  import {REQUEST_TYPES} from '@/enums/requestType';

  export default {
    name: 'ClientQuotes',
    props: ['request_type'],
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_ICONS,
        STATUSES,
        STATUS_COLORS,
        STATUS_ICONS,
        REQUEST_TYPES,
        requestId: this.$router.currentRoute.query.request_id,
        loading: true,
        defaultSortBy: 'date_proposal',
        defaultSortDesc: true,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        offers: [],
        rejectQueue: [],
        rejectId: null,
        viewedQueue: [],
      };
    },

    watch: {
      options: {
        handler() {
          this.clearOffers();
          this.getOffers();
        },
        deep: true,
      },
    },

    computed: {
      headers() {
        return [
          {text: localize('quoteDate'), value: 'date_proposal', width: 120},
          {text: localize('quotation'), value: 'proposal', width: 110},
          {text: localize('leadTime'), value: 'lead_time', width: 80},
          {text: localize('emissions') + ', kg', value: 'emissions', width: 80},
          {text: localize('deadlineForBooking'), value: 'deadline_for_booking', width: 125},
          {text: localize('quoteValidTo'), value: 'valid_to', width: 125},
          {text: localize('transportMode'), value: 'transport_mode_shortcode', sortable: false, width: 100},
          {text: localize('comments'), value: 'comment', align: 'center', width: '20%'},
          {text: '', value: 'buttons', align: 'center', sortable: false},
        ];
      },
    },

    methods: {
      clearOffers() {
        this.offers = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      queryData() {
        let queryData = {};
        queryData.request_id = this.requestId;
        queryData.per_page = this.itemsPerPage;
        queryData.page = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        return queryData;
      },
      setOffersData(requestsData) {
        this.offers = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      async getOffers() {
        try {
          this.loading = true;
          let requestsData = await api.getRequestOffers(this.queryData());
          this.setOffersData(requestsData);
          this.loading = false;
        } catch (e) {
          this.loading = false;
        }
      },
      openReject(item) {
        this.$refs.rejectQuoteDialog.open();
        this.rejectId = item.id;
      },
      cancelReject() {
        this.rejectId = null;
      },
      async rejectOffer() {
        let offerId = this.rejectId;
        this.rejectId = null;
        this.rejectQueue.push(offerId);
        try {
          await api.rejectOffer(offerId);
          let requestsData = await api.getRequestOffers(this.queryData());
          this.setOffersData(requestsData);
          this.rejectQueue = this.rejectQueue.filter(queueId => queueId !== offerId)
        } catch (e) {}
      },
      async viewedOffer(item) {
        let viewedId = item.id;
        this.viewedQueue.push(viewedId);
        try {
          await api.viewedOffer({'offer_id': viewedId});
          let requestsData = await api.getRequestOffers(this.queryData());
          let remoteData = await api.getSingleCustomerRequest(this.requestId);
          this.setOffersData(requestsData);
          let requestData = remoteData.data['request_form'];
          this.$emit('updateRequestData', requestData);
          this.viewedQueue = this.viewedQueue.filter(queueId => queueId !== viewedId)
        } catch (e) {}
      },
      isPriceRequestOnly() {
        return this.request_type === REQUEST_TYPES.PRICE_REQUEST;
      },
      isViewed(item) {
        return item.status === STATUSES.VIEWED;
      },
      isBooked(item) {
        return item.status === STATUSES.BOOKED;
      },
      isNotBooked(item) {
        return item.status === STATUSES.NOT_BOOKED;
      },
      isRejected(item) {
        return item.status === STATUSES.REJECTED;
      },
      isExpired(item) {
        return item.status === STATUSES.EXPIRED;
      },
      isDeclined(item) {
        return item.status === STATUSES.DECLINED;
      },
      isActionRequired(item) {
        return item.status === STATUSES.ACTION_REQUIRED;
      },
      isNew(item) {
        return item.status === STATUSES.NEW;
      },
      leadTimeDays(item) {
        return item > 1 ? localize('days') : localize('day')
      }
    },
  };
</script>

<style scoped lang="scss">
  .btn-fixed-width {
    width: 100px;
  }
</style>
